.image-description {
    background-image: url("../../../../../public/HotSpot_02.webp");
    background-size: cover;
}
.view-description-overright {
    height: 100%!important;
    align-items: flex-start!important;
}

.next-preview-image {
    height: 100%!important;
}