.pnlm-controls-container {
  display: none !important;
}
.pnlm-hotspot {
  width: 40px !important;
  height: 40px !important;
  background-clip: content-box !important;
  background-color: transparent !important;
  background-position: center !important;
  background-image: url("data:image/svg+xml,%3Csvg width='40px' height='40px' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d_1227_879)'%3E%3Ccircle cx='28' cy='28' r='20' fill='white'/%3E%3Ccircle cx='28' cy='28' r='18.5' stroke='url(%23paint0_radial_1227_879)' stroke-width='3'/%3E%3C/g%3E%3Cpath d='M33 28L25.5 32.3301L25.5 23.6699L33 28Z' fill='%2369C0B5'/%3E%3Cdefs%3E%3Cfilter id='filter0_d_1227_879' x='0' y='0' width='64' height='64' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dx='4' dy='4'/%3E%3CfeGaussianBlur stdDeviation='6'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0.411765 0 0 0 0 0.752941 0 0 0 0 0.709804 0 0 0 0.5 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1227_879'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_1227_879' result='shape'/%3E%3C/filter%3E%3CradialGradient id='paint0_radial_1227_879' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(16.4615 14.1538) rotate(60.3956) scale(38.9279)'%3E%3Cstop offset='0.0989583' stop-color='%23B9FCF3'/%3E%3Cstop offset='0.864583' stop-color='%2369C0B5'/%3E%3C/radialGradient%3E%3C/defs%3E%3C/svg%3E%0A") !important;
  &:hover {
    transition: ease-in-out 0.3s !important;
    background-image: url("data:image/svg+xml,%3Csvg width='40px' height='40px' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d_1227_448)'%3E%3Ccircle cx='28' cy='28' r='20' fill='%2369C0B5'/%3E%3Ccircle cx='28' cy='28' r='18.5' stroke='url(%23paint0_radial_1227_448)' stroke-width='3'/%3E%3C/g%3E%3Cpath d='M33 28L25.5 32.3301L25.5 23.6699L33 28Z' fill='white'/%3E%3Cdefs%3E%3Cfilter id='filter0_d_1227_448' x='0' y='0' width='64' height='64' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dx='4' dy='4'/%3E%3CfeGaussianBlur stdDeviation='6'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0.411765 0 0 0 0 0.752941 0 0 0 0 0.709804 0 0 0 0.2 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1227_448'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_1227_448' result='shape'/%3E%3C/filter%3E%3CradialGradient id='paint0_radial_1227_448' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(16.4615 14.1538) rotate(60.3956) scale(38.9279)'%3E%3Cstop offset='0.0989583' stop-color='white'/%3E%3Cstop offset='0.864583' stop-color='white'/%3E%3C/radialGradient%3E%3C/defs%3E%3C/svg%3E%0A") !important;
  }
}

.pnlm-pointer > .hotspot > .in {
  width: 40px !important;
  height: 40px !important;
  background-clip: content-box !important;
  background-color: transparent !important;
  background-image: url("data:image/svg+xml,%3Csvg width='40px' height='40px' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d_1010_1139)'%3E%3Ccircle cx='28' cy='28' r='20' fill='white'/%3E%3Ccircle cx='28' cy='28' r='18.5' stroke='url(%23paint0_radial_1010_1139)' stroke-width='3'/%3E%3C/g%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M28.5 22H27.5V27.5H22V28.5H27.5V34H28.5V28.5H34V27.5H28.5V22Z' fill='%231E93A1'/%3E%3Cdefs%3E%3Cfilter id='filter0_d_1010_1139' x='0' y='0' width='64' height='64' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dx='4' dy='4'/%3E%3CfeGaussianBlur stdDeviation='6'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0.411765 0 0 0 0 0.752941 0 0 0 0 0.709804 0 0 0 0.5 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1010_1139'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_1010_1139' result='shape'/%3E%3C/filter%3E%3CradialGradient id='paint0_radial_1010_1139' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(16.4615 14.1538) rotate(60.3956) scale(38.9279)'%3E%3Cstop offset='0.0989583' stop-color='%23B9FCF3'/%3E%3Cstop offset='0.864583' stop-color='%2369C0B5'/%3E%3C/radialGradient%3E%3C/defs%3E%3C/svg%3E");
  &:hover {
    transition: ease-in-out 0.3s !important;
  }
}

.pnlm-pointer > .hotspot {
  &:hover::after {
    display: none!important;
  }
}

.close-background {
  background-image: url("data:image/svg+xml,%3Csvg width='40px' height='40px' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d_1010_1139)'%3E%3Ccircle cx='28' cy='28' r='20' fill='white'/%3E%3Ccircle cx='28' cy='28' r='18.5' stroke='url(%23paint0_radial_1010_1139)' stroke-width='3'/%3E%3C/g%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M28.5 22H27.5V27.5H22V28.5H27.5V34H28.5V28.5H34V27.5H28.5V22Z' fill='%231E93A1'/%3E%3Cdefs%3E%3Cfilter id='filter0_d_1010_1139' x='0' y='0' width='64' height='64' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dx='4' dy='4'/%3E%3CfeGaussianBlur stdDeviation='6'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0.411765 0 0 0 0 0.752941 0 0 0 0 0.709804 0 0 0 0.5 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1010_1139'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_1010_1139' result='shape'/%3E%3C/filter%3E%3CradialGradient id='paint0_radial_1010_1139' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(16.4615 14.1538) rotate(60.3956) scale(38.9279)'%3E%3Cstop offset='0.0989583' stop-color='%23B9FCF3'/%3E%3Cstop offset='0.864583' stop-color='%2369C0B5'/%3E%3C/radialGradient%3E%3C/defs%3E%3C/svg%3E")!important;
}

.open-background {
  background-image: url("data:image/svg+xml,%3Csvg width='40px' height='40px' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d_1556_153)'%3E%3Ccircle cx='28' cy='28' r='20' fill='%2369C0B5'/%3E%3Ccircle cx='28' cy='28' r='18.5' stroke='url(%23paint0_radial_1556_153)' stroke-width='3'/%3E%3C/g%3E%3Cpath d='M21 29L21 27L36 27L36 29L21 29Z' fill='white'/%3E%3Cdefs%3E%3Cfilter id='filter0_d_1556_153' x='0' y='0' width='64' height='64' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dx='4' dy='4'/%3E%3CfeGaussianBlur stdDeviation='6'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0.411765 0 0 0 0 0.752941 0 0 0 0 0.709804 0 0 0 0.2 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1556_153'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_1556_153' result='shape'/%3E%3C/filter%3E%3CradialGradient id='paint0_radial_1556_153' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(16.4615 14.1538) rotate(60.3956) scale(38.9279)'%3E%3Cstop offset='0.0989583' stop-color='white'/%3E%3Cstop offset='0.864583' stop-color='white'/%3E%3C/radialGradient%3E%3C/defs%3E%3C/svg%3E")!important;
}

.pnlm-pointer > .hotspot > .out {
  display: none !important;
}

.pnlm-hot-spot-debug-indicator {
  display: none !important;
}

.loading-loader{
  background-repeat: no-repeat;
  background-size:200% 200% !important;
  background: 
    linear-gradient(to bottom, #6ac0b6 30%, #6ac0b6 50%, #ffffff 90%);
    animation: infiniteLoading 1s infinite;
  }

  @keyframes infiniteLoading {
    0% {
      background-position:100% 100%;
    }
  
    100% {
      background-position:0 0; 
    }
  }